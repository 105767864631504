
import lossRecheckPage from '@/components/caseInfo/lossRecheckPage.vue';
export default {
    components: {
        lossRecheckPage
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            navForm: {
                condition: {
                    caseInTimeStart: '',
                    caseInTimeEnd: '',
                    caseNo: '',
                    reviewStatus: [20],
                    reviewUserIds: []
                },
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            totalAmount: 0,
            tableData: [],
            multipleSelection: [],
            caseStepOption: [],
            caseEndStepOption: [],
            fetchCaseStepFlow: [],
            queryUserData: [],
            dialogForm: {
                userId: '',
                phone: ''
            },
            tabDictionary: {
                正式案件: '1',
                预报案案件: '2'
            },
            handleFlag: false,
            handleData: {},
            tableHeight: null,
            statisticsData: null
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.getReviewListData();
        this.getStatisticsData();
    },
    methods: {
        calcHeight() {
            let statisticsBoxHeight = document.querySelector('.statistics_box') ? document.querySelector('.statistics_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let guHeight = 20 + 62 + 102;
            this.tableHeight = window.innerHeight - statisticsBoxHeight - elPaginationHeight - guHeight;
        },
        getReviewListData(workFlag) {
            this.loading = true;
            this.dialogVisible = false;
            let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
            this.navForm.condition.reviewUserIds = [userInfo.userId];
            this.instance.post('/tpa/api/case/review/record/paging', this.navForm).then((res) => {
                this.loading = false;
                let data = res.data;
                if (res.data.code == 200 && res.data.data) {
                    this.tableData = res.data.data.list || [];
                    this.navForm.total = data.data.total;
                    if (workFlag) {
                        this.showCaseInfo(this.tableData[0]);
                    }
                } else {
                    this.navForm.total = 0;
                }
            });
        },
        getStatisticsData() {
            this.statisticsData = null;
            this.instance.get('/tpa/api/case/review/record/review-status/statistics-by-user-tool', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.statisticsData = data.data[0];
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getReviewListData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getReviewListData();
        },
        showCaseInfo(rowItem) {
            rowItem.editReview = true;
            this.handleData = rowItem;
            this.dialogVisible = true;
        },
        closeCaseDetail() {},
        startWorking() {
            this.getReviewListData(true);
        }
    }
};
